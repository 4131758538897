import jwtDecode from "jwt-decode";
import { notification } from "antd";
import moment from "moment-timezone";

import meeting from "../assets/images/meeting.png";
import office from "../assets/images/office.png";
import desk from "../assets/images/desk.png";

import flexi_desk_icon from "../website/images/hd_1.png";
import dedicated_desk_icon from "../website/images/hd_2.png";
import private_office_icon from "../website/images/hd_3.png";

import { Store } from "../config/Store";
import Routes from "../config/Routes";
import constants from "./Constants";
import DefaultComponent from "../cms/foldComponents/DefaultComponent";
import TestimonialComponent from "../cms/foldComponents/TestimonialComponent";
import FaqComponent from "../cms/foldComponents/FaqComponent";
import ListComponent from "../cms/foldComponents/ListComponent";

const { CMS_TYPES, CMS_PAGES } = constants;
const { SERVICE_TYPE, HOME_PAGE, AVAILABILITY_PAGE, OFFER_PAGE } = CMS_TYPES;

const colors = {};
export const colorArr = [
  "#3ac48a",
  "orange",
  "#ffa16c",
  "#697789",
  "#329aff",
  "#4ed6e7",
  "#ff6378",
  "#893037",
  "#606371",
  "#E55B5B",
  "#2F5090",
  "#196EEF",
  "#5C33D1",
  "#9B51E0",
];
let lastColorIndex = 0;

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const segmentedDays = ["Days", "Months", "Longer"];

export const cmsFoldPages = [
  {
    title: "Home Page",
    route: Routes.homePage,
    cms_type: HOME_PAGE,
    page_name: CMS_PAGES.HOME_PAGE,
  },
  {
    title: "Meeting Room Page",
    route: Routes.meetingRoomPage,
    cms_type: SERVICE_TYPE.MEETING_ROOM,
    page_name: CMS_PAGES.SERVICE_PAGE.MEETING_ROOM,
    /* show_edit_meta_details: false, */
  },
  {
    title: "Private Office Page",
    route: Routes.privateOfficePage,
    cms_type: SERVICE_TYPE.PRIVATE_OFFICE,
    page_name: CMS_PAGES.SERVICE_PAGE.PRIVATE_OFFICE,
    /* show_edit_meta_details: false, */
  },
  {
    title: "Dedicated Desk Page",
    route: Routes.dedicatedDeskPage,
    cms_type: SERVICE_TYPE.DEDICATED_DESK,
    page_name: CMS_PAGES.SERVICE_PAGE.DEDICATED_DESK,
    /* show_edit_meta_details: false, */
  },
  {
    title: "Flexi Desk Page",
    route: Routes.flexiDeskPage,
    cms_type: SERVICE_TYPE.FLEXI_DESK,
    page_name: CMS_PAGES.SERVICE_PAGE.FLEXI_DESK,
    show_edit_meta_details: false,
  },
  {
    title: "Availability Page",
    route: Routes.availabilityPage,
    cms_type: AVAILABILITY_PAGE,
    page_name: CMS_PAGES.AVAILABILITY_PAGE,
    show_edit_meta_details: false,
  },
  {
    title: "Offer Page",
    route: Routes.foldableOfferPage,
    cms_type: OFFER_PAGE,
    page_name: CMS_PAGES.OFFER_PAGE,
    show_edit_meta_details: false,
  },
  {
    title: "About Us Page",
    route: Routes.aboutUs,
    cms_type: CMS_TYPES.ABOUT_US,
    page_name: CMS_PAGES.ABOUT_US,
  },
  {
    title: "Office Listing Page",
    route: Routes.officeList,
    cms_type: CMS_TYPES.PAGE_FOLD,
    page_name: CMS_PAGES.OFFICE_LIST,
    page_id: CMS_PAGES.OFFICE_LIST,
  },
];

export const FOLD_TYPE = {
  default: {
    _id: "default",
    title: "Default (Image, Title & Description)",
    component: DefaultComponent,
  },
  testimonial: {
    _id: "testimonial",
    title: "Testimonial",
    component: TestimonialComponent,
  },
  faqs: {
    _id: "faqs",
    title: "FAQs",
    component: FaqComponent,
  },
  list: {
    _id: "list",
    title: "List (Multiple Images, Title & Description)",
    component: ListComponent,
  },
};

export const resourceTypes = {
  meeting_room: {
    _id: "meeting_room",
    name: "Meeting Room",
    image: meeting,
    icon: private_office_icon,
    desc: "Your own lockable Meeting Room",
  },
  private_office: {
    _id: "private_office",
    name: "Private Office",
    image: office,
    icon: private_office_icon,
    desc: "Your own lockable Private Office",
  },
  dedicated_desk: {
    _id: "dedicated_desk",
    name: "Dedicated Desk",
    image: desk,
    icon: dedicated_desk_icon,
    desc: "Your own Dedicated desk in a shared office",
    color: "geekblue",
  },
  flexi_desk: {
    _id: "flexi_desk",
    name: "Flexi Desk",
    image: desk,
    icon: flexi_desk_icon,
    desc: "Access to Hot Desks in shared spaces",
    color: "orange",
  },
};

export function getAvatarColor(id, transparency) {
  if (!id) {
    return colorArr[Math.floor(Math.random() * colorArr.length)];
  }
  if (!colors.hasOwnProperty(id)) {
    colors[id] = colorArr[lastColorIndex];
    lastColorIndex = lastColorIndex + 1;
    lastColorIndex = lastColorIndex % colorArr.length;
  }
  return transparency ? `${colors[id]}${transparency}` : colors[id];
}

export const getNameInitials = (name) => {
  name = name && name.trim();
  if (!name) return;
  let initials = name[0] || "";
  var index = name.indexOf(" ");
  if (index < name.length && index > 1) {
    initials += name[index + 1];
  }
  return initials.toUpperCase();
};

export const isUserAuthenticated = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    let user = jwtDecode(token);
    let dateNow = new Date();
    if (user.exp > dateNow.getTime() / 1000) {
      return true;
    }
  }
  return false;
};

export const checkSpecialCharacters = (name) => {
  let nameformat = /^[A-Za-z ]+$/;
  return nameformat.test(name);
};

export const validateEmail = (email) => {
  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return mailformat.test(email);
};

export const isValidPassword = (password = "") => {
  return password?.length >= 4;
};

export const showNotification = (type, msg) => {
  notification[type]({
    message: msg,
    placement: "bottomLeft",
  });
};

export const escapeRegex = (string) => {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const isValidMobileNumber = (countryCode = "", mobile = "") => {
  let format = /^[789]\d{9}$/;

  if (countryCode === "91") format = /^[789]\d{9}$/;
  if (countryCode === "44") format = /^(07[\d]{8,12}|447[\d]{7,11})$/;

  if (!countryCode || !format) return false;

  return format.test(mobile);
};

export const disabledDate =
  (
    params = {
      isFutureDateDisabled: false,
      activeDays: days,
      toDisabledDate: null,
    }
  ) =>
  (current) => {
    const {
      isFutureDateDisabled = false,
      activeDays = days,
      toDisabledDate,
    } = params;

    const currentDay = moment(current).day();
    const isEnabled = activeDays.includes(days[currentDay]);
    if (!isEnabled) return true;

    // Can not select days after today
    if (isFutureDateDisabled) return current && current > moment().endOf("day");

    // Can not select days before from to disabled date
    if (toDisabledDate) {
      return current && current < moment(toDisabledDate).endOf("day");
    }

    // Can not select days before today
    return current && current < moment().startOf("day");
  };
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledHours = (workspace, from_date) => {
  const office_hours_start = workspace?.office_hours?.[0];
  const office_hours_end = workspace?.office_hours?.[1];

  let start_hour = moment(office_hours_start).format("HH");
  let end_hour = moment(office_hours_end).format("HH");

  let disabled_Hours = [...new Array(24).keys()]?.filter(
    (t) => t < start_hour || t > end_hour
  );

  if (from_date && moment().startOf("day").isSame(from_date, "day")) {
    let hr = moment().format("HH");
    let min = moment().format("mm");
    if (min > 55) ++hr;
    return [...new Set(disabled_Hours?.concat(range(0, hr)))];
  }
  return disabled_Hours;
};

export const disabledMinutes = (from_date, from_time) => {
  if (from_date && moment().startOf("day").isSame(from_date, "day")) {
    let min = moment().format("mm");
    if (moment(from_time).hour() === moment().hour()) {
      return range(0, min);
    }
  }
};

export const checkPermission = (permission) => {
  let { row = "", subRow = "", type = "editable", initial = "" } = permission;
  let mPermissions = Store.getState()?.user?.loggedInUser?.role_id?.permissions;
  if (initial) return mPermissions?.[initial];

  let hasPermission = subRow
    ? mPermissions?.[row]?.[subRow]?.[type]
    : mPermissions?.[row]?.[type];
  return hasPermission;
};

export const arrToObjMap = (arr = []) => {
  const obj = arr.reduce((mObj, item) => {
    var key = item?._id || item?.id;
    mObj[key] = item;
    return mObj;
  }, {});

  return Object.assign({}, obj);
};

export const getArrOrder = (arr = []) => {
  let order = arr?.map((a) => a?._id || a?.id);
  return order;
};

export const formatDateTime = (value = "", type = "dt") => {
  switch (type) {
    //dt means format date and time
    case "dt":
      return moment(value).format("YYYY-MM-DDTHH:mm:ssZ");

    //d means date format only
    case "d":
      return moment(value).format("YYYY-MM-DD");

    //t means time format only
    case "t":
      return moment(value).format("HH:mm");

    default:
      return moment(value).format("DD-MM-YYYY HH:mm:ss");
  }
};
export const getUserWorkspace = () => {
  const user = Store.getState()?.user?.loggedInUser;
  if (!user) return [];
  let { workSpaceList, workSpaceMap } = Store.getState()?.workSpace;
  const showAllWorkSpaces = checkPermission({ initial: "showAllWorkSpaces" });
  const showAllBooking = checkPermission({ initial: "showAllBooking" });

  const spaces = user?.spaces
    ?.filter((space) => (showAllBooking ? space?.active : !space?.active))
    ?.map((obj) => obj?.space);

  let allowedWorkSpace;
  if (showAllWorkSpaces && showAllBooking) {
    return workSpaceList;
  } else if (showAllBooking) {
    allowedWorkSpace = workSpaceList?.filter((space_id) =>
      spaces?.includes(space_id)
    );
  } else {
    workSpaceList = Object.keys(workSpaceMap)?.filter(
      (id) => workSpaceMap?.[id]?.active
    );
    allowedWorkSpace = workSpaceList?.filter(
      (space_id) => !spaces?.includes(space_id)
    );
  }

  return allowedWorkSpace;
};

export const deskTypeCount = (deskItems = [], date = moment()) => {
  let desk_type = [];
  let desk_types_count = {
    flexi_desk: {
      total: 0,
      booked: 0,
    },
    dedicated_desk: {
      total: 0,
      booked: 0,
    },
  };

  if (Array.isArray(deskItems)) {
    deskItems.map((item) => {
      if (item?.status) {
        const today = date;
        const booking_slot = item?.booking_slot || [];
        const isBooked =
          booking_slot?.length > 0 &&
          booking_slot?.find((bs) =>
            today.isBetween(
              moment(bs?.from).startOf("day"),
              moment(bs?.to).endOf("day")
            )
          );

        desk_types_count[item?.desk_type] = Object.assign(
          {},
          desk_types_count[item?.desk_type],
          {
            total: (desk_types_count[item?.desk_type]?.total ?? 0) + 1,
            booked:
              (desk_types_count[item?.desk_type]?.booked ?? 0) +
              (isBooked ? 1 : 0),
          }
        );
      }
    });
  }

  Object.keys(desk_types_count).map((dt) =>
    desk_type.push({ [dt]: desk_types_count[dt] })
  );

  return desk_type;
};

export const searchStringToObjMap = (search = "") => {
  let searchString = search.replace("?", "");
  let params = {};

  searchString.split("&").forEach((ss) => {
    const param = ss.split("=");
    params[param[0]] = param[1];
  });

  return params;
};

export const calculateBusinessDays = (
  startDate,
  endDate,
  activeDays = days
) => {
  const currentDay = moment(startDate);
  let businessDays = 0;

  while (currentDay.isSameOrBefore(endDate, "days")) {
    if (activeDays.includes(days[currentDay.day()])) {
      businessDays++;
    }

    currentDay.add(1, "days");
  }
  return businessDays;
};

const stripeCardBgColor = [
  {
    bgColor: "#0d2b29",
    overlayColor:
      "radial-gradient(72.36% 228.36% at 30.96% 42.05%, rgba(73, 151, 151, 0.5) 0%, rgba(4, 46, 70, 0) 100%)",
  },
  {
    bgColor: "#2b0d26",
    overlayColor:
      "radial-gradient(72.36% 228.36% at 30.96% 42.05%, rgba(151, 73, 73, 0.5) 0%, rgba(13, 4, 70, 0) 100%)",
  },
  {
    bgColor: "#2b0d0d",
    overlayColor:
      "radial-gradient(72.36% 228.36% at 30.96% 42.05%, rgba(151, 115, 73, 0.5) 0%, rgba(4, 46, 70, 0) 100%)",
  },
  {
    bgColor: "#110D2B",
    overlayColor:
      "radial-gradient(72.36% 228.36% at 30.96% 42.05%, rgba(107, 73, 151, 0.5) 0%, rgba(4, 46, 70, 0) 100%)",
  },
  {
    bgColor: "#3D370B",
    overlayColor:
      "radial-gradient(72.36% 228.36% at 30.96% 42.05%, rgba(151, 143, 73, 0.5) 0%, rgba(13, 4, 70, 0) 100%)",
  },
];

export const getStripeCardColor = (cardIndex = 0) => {
  const cardColorIndex = cardIndex % stripeCardBgColor.length;
  return stripeCardBgColor[cardColorIndex];
};

export const getTagFreeContent = (text = "") =>
  text?.replace(/(<([^>]+)>)/gi, "");

export const addMinutesToTime = (time, minutesToAdd = 0) => {
  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes + minutesToAdd;
  return totalMinutes;
};

export const durationMinutes = (duration = 0) => {
  return duration;
};
