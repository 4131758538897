import { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Select,
  Space,
  DatePicker,
  TimePicker,
  Input,
  Button,
  Divider,
  Tag,
  Tooltip,
  Popconfirm,
} from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import CustomModal from "../../utils/CustomModal";
import {
  bookResource,
  getAllAvailableResources,
  updateBooking,
  cancelBooking,
} from "../duck/BookingActions";
import moment from "moment-timezone";
import { TeamOutlined, SwapRightOutlined } from "@ant-design/icons";
import { addMember, getAllMembers } from "../../members/duck/MembersActions";
import {
  disabledDate,
  disabledHours,
  disabledMinutes,
  resourceTypes,
  validateEmail,
  addMinutesToTime,
  durationMinutes,
} from "../../utils/CommonFunctions";
import { checkPermission, formatDateTime } from "../../utils/CommonFunctions";

const { RangePicker } = DatePicker;

class NewBooking extends Component {
  state = {
    resource_type: undefined,
    capacity: undefined,
    from_date: null,
    from_time: null,
    duration: null,
    from_to_date: null,
    amenities_id: undefined,
    resource: undefined,
    member: undefined,
    filter: "",
    title: "",
    email: "",
    error: {},
    enableReschedule: false,
    reschedule_reason: "",
  };

  componentDidUpdate(prevProps) {
    const {
      selectedBooking,
      visible,
      activeWorkspace,
      getAllAvailableResources,
      fromToDate,
      activeSpace,
      mMemberBoard = {},
    } = this.props;

    let {
      from_date,
      from_time,
      duration,
      from_to_date,
      resource_type,
      capacity,
      amenities_id,
      enableReschedule,
    } = this.state;

    // const currentDate = new Date();
    // const formattedCurrentDate = moment(currentDate).format("DD-MM-YYYY");
    // const formattedCurrentTime = moment(currentDate).format("HH:mm");
    // const UpdateDate = moment(from_date).format("DD-MM-YYYY");
    // const editStartTime = moment(from_time).format("HH:mm");
    // const editEndTime = moment(from_time).add(duration, "hour").format("HH:mm");
    // if (
    //   formattedCurrentDate === UpdateDate &&
    //   formattedCurrentTime !== editStartTime
    // ) {
    //   if (
    //     formattedCurrentTime < editEndTime &&
    //     formattedCurrentTime > editStartTime
    //   ) {
    //     console.log("time set");
    //   }
    // }

    if (prevProps.visible !== visible) {
      let _start = selectedBooking
        ? moment(selectedBooking?.from)
        : moment(fromToDate?.from);
      let _end = selectedBooking
        ? moment(selectedBooking?.to)
        : moment(fromToDate?.to);
      let duration = moment.duration(_end.diff(_start));
      let resource_type =
        selectedBooking?.type ||
        selectedBooking?.items[0]?.desk_type ||
        selectedBooking?.items[0]?.type ||
        selectedBooking?.resource_id?.type ||
        undefined;

      // let members =
      //   selectedBooking?.members?.map?.((m) => m?.email || m?.number || m) ||
      //   undefined;
      let members =
        selectedBooking?.members?.map?.((member) => {
          if (typeof member === "string") {
            return member;
          } else if (typeof member === "object") {
            let { name = "", email = "", number = "" } = member;
            name = name?.trim?.();
            email = email?.trim?.();
            number = number?.trim?.();

            if (name && email) {
              return `${name}(${email})`;
            } else {
              return name || email || number;
            }
          } else {
            return null;
          }
        }) || undefined;
      if (Array.isArray(members) && members.length > 0) {
        members = members.filter((m) => m);
      }

      this.setState({
        resource_type: resource_type,
        capacity: selectedBooking?.capacity || 1,
        from_date:
          (resource_type === "meeting_room" &&
            selectedBooking?.from &&
            moment(selectedBooking?.from)) ||
          null,
        from_time:
          (resource_type === "meeting_room" &&
            selectedBooking?.from &&
            moment(selectedBooking?.from)) ||
          null,
        duration:
          (resource_type === "meeting_room" &&
            Math.round(duration?.asHours() * 10) / 10) ||
          null,
        from_to_date: [_start, _end],
        amenities_id: selectedBooking?.amenities_id,
        resource:
          (selectedBooking?.resource_id && selectedBooking?.resource_id?._id) ||
          selectedBooking?.resource_id ||
          undefined,
        isDeskSwapRequestRaise: !!selectedBooking?.seat_swap_status,
        customer_remark: selectedBooking?.customer_remark,
        assigned_to: selectedBooking?.assignedUser?.email || undefined,
        member: members,
        filter: "",
        title: selectedBooking?.title || "",
        email: "",
        error: {},
        enableReschedule: false,
        reschedule_reason: selectedBooking?.reschedule_reason || "",
      });
    }

    // call to api to fetch all the available resources
    if (
      visible &&
      (!selectedBooking || enableReschedule) &&
      resource_type &&
      activeSpace
    ) {
      let startDateTime;
      let endDateTime;
      if (resource_type === "meeting_room") {
        if (!from_time || !duration) return;
        startDateTime = moment(
          `${formatDateTime(from_date, "d")} ${formatDateTime(from_time, "t")}`
        );
        endDateTime = moment(startDateTime).add(duration, "hours");
      } else {
        if (!from_to_date) return;

        const office_hours_start = activeSpace?.office_hours?.[0];
        const office_hours_end = activeSpace?.office_hours?.[1];

        startDateTime = moment(from_to_date[0]).set({
          hour: moment(office_hours_start).format("HH"),
          minute: moment(office_hours_start).format("mm"),
        });
        endDateTime = moment(from_to_date[1]).set({
          hour: moment(office_hours_end).format("HH"),
          minute: moment(office_hours_end).format("mm"),
        });
      }

      const availableResParams = {
        from: startDateTime.utc().toDate(),
        to: endDateTime.utc().toDate(),
        capacity: capacity,
        amenities_id: amenities_id,
        space_id: activeWorkspace,
        type: resource_type,
      };
      if (selectedBooking) availableResParams.booking_id = selectedBooking?._id;

      let filter = JSON.stringify(availableResParams);
      if (filter !== this.state.filter) {
        this.setState({
          filter: filter,
          resource: null,
        });

        getAllAvailableResources(availableResParams);
      }
    }

    if (
      (prevProps?.selectedBooking?.loading &&
        !this.props.selectedBooking?.loading) ||
      (prevProps?.booking?.loading && !this.props.booking?.loading)
    ) {
      this.props.handleModal(false);
    }

    if (this.showAllBooking) {
      const { list, loading } = mMemberBoard;
      if (prevProps?.activeWorkspace !== activeWorkspace) {
        this.loadMoreMembers();
      } else if (!loading && list?.length > 0) {
        this.loadMoreMembers(list?.length);
      }
    }
  }

  loadMoreMembers = (offset = 0, limit = 100) => {
    const { mMemberBoard, activeWorkspace } = this.props;
    const { meta, list, loading } = mMemberBoard;

    if (
      activeWorkspace &&
      !loading &&
      (offset === 0 || meta?.totalCount > list?.length)
    ) {
      const params = {
        space_id: activeWorkspace,

        offset,
        limit,
      };

      this.props.getAllMembers(params);
    }
  };

  handleChange = (name) => (event) => {
    // test

    const value = event?.target?.value ?? event?.target?.checked ?? event;
    console.log(moment(value).format("HH:mm"), "value", name, "name");

    let newState = {};

    switch (name) {
      case "member":
      case "assigned_to":
      case "resource":
      case "reschedule_reason":
        newState = {
          [name]: value,
          // error: undefined,
        };
        break;

      default:
        newState = {
          [name]: value,
          resource: null,
          // error: undefined,
        };
        break;
    }

    let { capacity, member } = this.state;
    if (name === "capacity" && capacity > value && member?.length > value) {
      newState = Object.assign({}, newState, {
        member: member?.slice(0, value),
        error: null,
      });
    }
    this.setState(newState);
  };

  hasError = () => {
    let {
      resource_type,
      capacity,
      from_date,
      from_time,
      duration,
      from_to_date,
      resource,
      assigned_to,
      member,
      reschedule_reason,
    } = this.state;
    const { selectedBooking } = this.props;

    let error = {};

    if (!resource_type) {
      error.resource_type = "Please select resource type";
    }

    if (!capacity) {
      error.capacity = "Capacity must be greater than 0";
    }

    if (resource_type === "meeting_room") {
      if (!from_date || !from_time) {
        error.from_date_time = "Please select date and time";
      }

      if (!duration) {
        error.duration = "Please enter duration";
      }
    } else {
      if (!from_to_date) {
        error.from_to_date = "Please select date range";
      }
    }

    if (!resource) {
      error.resource = "Please select resource";
    }

    if (selectedBooking) {
      reschedule_reason = reschedule_reason?.trim?.();
      if (!reschedule_reason) {
        error.reschedule_reason = "Please enter remark";
      }
    } else {
      if (!assigned_to) {
        error.assigned_to = "Please select billed to member";
      }

      if (!member || member?.length === 0) {
        error.member = "Please select member";
      }
    }

    console.log("error", error);

    this.setState({
      error,
    });

    return Object.keys(error).length >= 1;
  };

  onBookNow = () => {
    if (!this.hasError()) {
      const {
        from_date,
        from_time,
        duration,
        resource,
        assigned_to,
        member,
        resource_type,
        title,
        from_to_date,
        filter,
        capacity,
        reschedule_reason,
      } = this.state;
      const {
        activeWorkspace,
        selectedBooking,
        bookingStatus,
        availableResources,
        activeSpace,
      } = this.props;
      const mAvailableResources = availableResources?.[filter];

      let startDateTime;
      let endDateTime;

      if (resource_type === "meeting_room") {
        if (!from_time || !duration) return;
        startDateTime = moment(
          `${formatDateTime(from_date, "d")} ${formatDateTime(from_time, "t")}`
        );
        endDateTime = moment(startDateTime).add(duration, "hours");
      } else {
        if (!from_to_date) return;
        const [start, end] = activeSpace?.office_hours;
        startDateTime = moment(from_to_date[0]).set({
          hour: moment(start).format("HH"),
          minute: moment(start).format("mm"),
        });
        endDateTime = moment(from_to_date[1]).set({
          hour: moment(end).format("HH"),
          minute: moment(start).format("mm"),
        });
      }

      let items = [];
      let selectedResource;
      if (
        filter &&
        mAvailableResources &&
        typeof resource_type === "string" &&
        Array.isArray(mAvailableResources?.list)
      ) {
        selectedResource = mAvailableResources?.list?.find(
          (res) => res?._id === resource
        );
        items = selectedResource?.items?.slice(0, capacity);
        items = items?.map?.((i) => i?._id);
      }
      let bookResProps = {
        from: startDateTime.utc().toDate(),
        to: endDateTime.utc().toDate(),
        title,
        resource_id: resource,
        dynamic_price: selectedResource?.dynamic_price,
        // assignedUser: assigned_to,
        // members: member,
        space_id: activeWorkspace,
        ids: items,
        type: resource_type,
        capacity,
      };

      if (selectedBooking && bookingStatus !== "new") {
        if (reschedule_reason) {
          bookResProps.reschedule_reason = reschedule_reason;
        }

        this.props.updateBooking({
          ...bookResProps,
          _id: selectedBooking?._id,
        });
      } else {
        this.props.bookResource({
          ...bookResProps,
          // resource_id: resource,
          // dynamic_price: selectedResource?.dynamic_price,
          assignedUser: assigned_to,
          members: member,
          // space_id: activeWorkspace,
          // ids: items,
          // type: resource_type,
          // capacity,
        });
      }
    }
  };

  showAllBooking = checkPermission({ initial: "showAllBooking" });

  onInviteMember = () => {
    let { activeWorkspace } = this.props;
    let { email } = this.state;
    email = email?.trim?.();

    let error = {};
    if (!email) {
      error.email = "Please enter member email";
    } else if (!validateEmail(email)) {
      error.email = "Please enter valid email";
    }

    if (Object.keys(error).length >= 1) {
      this.setState({ error });
    } else {
      let params = {
        space_id: activeWorkspace,
        email,
      };
      this.props.addMember(params);

      this.setState({
        email: "",
        error: {},
      });
    }
  };

  _dropdownRender = (menu) => {
    const { email, error } = this.state;

    return (
      <div>
        {menu}
        <Divider style={{ margin: "4px 0" }} />

        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
          <Col span={17}>
            <Input
              style={{ flex: "auto" }}
              className="custom-input"
              placeholder="Enter email"
              value={email}
              onChange={this.handleChange("email")}
            />

            <Row className="error mt5">{error?.email}</Row>
          </Col>

          <Col>
            <Button type="link" onClick={this.onInviteMember}>
              + Invite via email
            </Button>
          </Col>
        </div>
      </div>
    );
  };

  onMemberListVisibleChange = (isOpen) => {
    if (!isOpen) {
      this.setState((preState) => ({
        email: "",
        error: { ...(preState?.error || {}), email: "" },
      }));
    }
  };

  onRescheduleBooking = () => {
    this.setState({
      enableReschedule: true,
    });
  };

  /*   addMinutesToTime = (time, minutesToAdd = 0) => {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes + minutesToAdd;
    return totalMinutes;
  };

  durationMinutes = (duration = 0) => {
    return duration;
  }; */

  checkAllConditions = () => {
    //const currentTime = moment.tz("Europe/London").format("HH:mm:ss");
    const office_hours_start = this.props.activeSpace?.office_hours?.[0];
    const office_hours_end = this.props.activeSpace?.office_hours?.[1];
    const startDateTime = moment(office_hours_start).format("HH:mm:ss");
    const endDateTime = moment(office_hours_end).format("HH:mm:ss");
    const durationMinutesData = durationMinutes(this.state?.duration);
    const minutes = durationMinutesData * 60;
    const initialTime = moment(this.state.from_time?._d);
    const newTime = initialTime.subtract(0, "hours");
    const selectTime = newTime.format("HH:mm:ss");
    const case1 =
      this.state.resource_type === "meeting_room" &&
      this.state.from_date !== null &&
      this.state.from_time !== null &&
      addMinutesToTime(selectTime) < addMinutesToTime(startDateTime);

    const case2 =
      this.state.resource_type === "meeting_room" &&
      this.state.from_date !== null &&
      this.state.from_time !== null &&
      addMinutesToTime(selectTime, minutes) > addMinutesToTime(endDateTime);

    if (case1) {
      return true;
    } else if (case2) {
      return true;
    } else {
      return false;
    }
  };

  workSpaceStartEndTimeMsg = () => {
    const office_hours_start = this.props.activeSpace?.office_hours?.[0];
    const office_hours_end = this.props.activeSpace?.office_hours?.[1];
    const startDateTime = moment(office_hours_start).format("hh:mm A");
    const endDateTime = moment(office_hours_end).format("hh:mm A");
    const msg = `Our workspace is open from ${startDateTime} to ${endDateTime}. Please contact info@go-bermondsey.co.uk for assistance with bookings outside these hours.`;
    return msg;
  };

  render() {
    const {
      activeSpace,
      visible,
      selectedBooking,
      handleModal,
      availableResources,
      bookingStatus,
      booking,
      activeMemberEmails,
      editable,
      loggedInUser,
      bookingId,
    } = this.props;

    const {
      resource_type,
      capacity,
      from_date,
      from_time,
      duration,
      from_to_date,
      resource,
      isDeskSwapRequestRaise,
      customer_remark,
      assigned_to,
      member,
      filter,
      title,
      error,
      enableReschedule,
      reschedule_reason,
    } = this.state;

    const canCancel =
      this.showAllBooking ||
      selectedBooking?.assignedUser?.email === loggedInUser?.email;
    const isNotNewBooking = selectedBooking && bookingStatus !== "new";

    const disabled =
      bookingStatus === "canceled" ||
      bookingStatus === "past" ||
      // bookingStatus === "ongoing" ||
      booking?.loading ||
      selectedBooking?.loading ||
      this.props.disabled;
    const modalTitle =
      // bookingStatus === "past" || bookingStatus === "ongoing"
      bookingStatus === "past"
        ? "Booking"
        : isNotNewBooking
        ? "Update Booking"
        : "New Booking";

    let members = [...(activeMemberEmails || [])];

    members = members?.filter?.((m) => m && !member?.includes?.(m));
    const mAvailableResources = availableResources?.[filter]?.list;
    const activeAmenities =
      resource &&
      mAvailableResources
        ?.find((obj) => obj?._id === resource)
        ?.amenities_id?.filter((a) => a?.active);

    const isDisabledAll = isNotNewBooking || disabled;
    const isReschedule = enableReschedule && isNotNewBooking && !disabled;

    return (
      <>
        <CustomModal
          width={700}
          visible={visible}
          title={
            <Row align="middle" justify="space-between">
              <Col>{modalTitle}</Col>

              {/* <Col className="mr40">{modalTitle}</Col> */}
              {bookingId && (
                <Tooltip
                  className="mr40"
                  placement="top"
                  title="Booking History"
                >
                  <HistoryOutlined onClick={this.props.onShowBookingHistory} />
                </Tooltip>
              )}
            </Row>
          }
          onCancel={handleModal}
          footer={
            <>
              <Button size="large" onClick={handleModal}>
                Close
              </Button>

              {!this.props.disabled &&
                editable &&
                bookingStatus !== "past" &&
                // bookingStatus === "ongoing" &&
                selectedBooking?.payment_status !== "failed" &&
                bookingStatus !== "canceled" && (
                  <>
                    {!enableReschedule && isNotNewBooking && canCancel && (
                      <Popconfirm
                        title={() => (
                          <h3>Are you sure to cancel this booking?</h3>
                        )}
                        onConfirm={() => {
                          this.props.cancelBooking(selectedBooking?._id);
                        }}
                        // onCancel={this.cancel}
                        okText="Yes, Please"
                        loading={selectedBooking?.loading}
                        cancelText="No"
                        cancelButtonProps={{ size: "medium" }}
                        okButtonProps={{ size: "medium" }}
                      >
                        <Button
                          type="danger"
                          size="large"
                          loading={selectedBooking?.loading}
                        >
                          Cancel
                        </Button>
                      </Popconfirm>
                    )}

                    {!selectedBooking && (
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.onBookNow}
                        loading={booking?.loading || selectedBooking?.loading}
                        className={
                          this.checkAllConditions() ? "disableButton" : ""
                        }
                      >
                        Book Now
                      </Button>
                    )}

                    {!enableReschedule &&
                      selectedBooking &&
                      resource_type === "meeting_room" && (
                        <Button
                          type="primary"
                          size="large"
                          onClick={this.onRescheduleBooking}
                          loading={booking?.loading || selectedBooking?.loading}
                        >
                          Edit
                        </Button>
                      )}

                    {enableReschedule && (
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.onBookNow}
                        loading={booking?.loading || selectedBooking?.loading}
                      >
                        Update
                      </Button>
                    )}
                  </>
                )}
            </>
          }
        >
          <div
            className="--avatar mb10"
            style={{
              width: "100%",
              backgroundColor: "#EEEDFD",
              fontWeight: 500,
            }}
          >
            Booking resources
          </div>

          {this.checkAllConditions() && (
            <Row align="middle" className="mt10">
              <Col span={24} style={{ color: "red", textAlign: "center" }}>
                {this.workSpaceStartEndTimeMsg()}
              </Col>
            </Row>
          )}

          {/* Resource Type start */}
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Resource Type</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              {isDisabledAll ? (
                <span>{resourceTypes?.[resource_type]?.name}</span>
              ) : (
                <Select
                  className="custom-select fw"
                  placeholder="Select resource type"
                  size="large"
                  allowClear
                  value={resource_type}
                  onChange={this.handleChange("resource_type")}
                  disabled={isDisabledAll}
                >
                  {Object.values(resourceTypes).map(({ _id, name }) => (
                    <Select.Option key={_id} value={_id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              )}

              <Row className="error mt5">{error?.resource_type}</Row>
            </Col>
          </Row>
          {/* Resource Type end */}

          {/* Office name or Title start */}
          {(resource_type === "meeting_room" ||
            resource_type === "private_office") && (
            <Row align="middle">
              {((isDisabledAll && title) || !selectedBooking) && (
                <Col span={6}>
                  <label className="label">
                    {resource_type === "private_office"
                      ? "Office name"
                      : "Title"}
                  </label>
                </Col>
              )}

              <Col span={18}>
                {!selectedBooking ? (
                  <Input
                    className="custom-input"
                    placeholder={
                      resource_type === "private_office"
                        ? "Office name"
                        : "Title"
                    }
                    size="large"
                    allowClear
                    value={title}
                    onChange={this.handleChange("title")}
                    disabled={isDisabledAll}
                  />
                ) : (
                  isDisabledAll && title && <span>{title}</span>
                )}
              </Col>
            </Row>
          )}
          {/* Office name or Title end */}

          {/* Date & time start */}
          {resource_type === "meeting_room" ? (
            <>
              <Row align="middle" className="mt10">
                <Col span={6}>
                  <label className="label">From</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18}>
                  {isDisabledAll && !isReschedule ? (
                    <Row>
                      <span className="mr20">
                        {moment(from_date).format("DD-MM-YYYY")}
                      </span>
                      <span>{moment(from_time).format("HH:mm")}</span>
                    </Row>
                  ) : (
                    <Space direction="horizontal" size={12}>
                      <DatePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={from_date}
                        onChange={this.handleChange("from_date")}
                        disabled={isDisabledAll && !isReschedule}
                        disabledDate={disabledDate({
                          activeDays: activeSpace?.no_of_days,
                        })}
                        className="custom-input"
                      />

                      <TimePicker
                        size="large"
                        format="HH:mm"
                        value={from_time}
                        minuteStep={5}
                        onSelect={this.handleChange("from_time")}
                        onChange={this.handleChange("from_time")}
                        disabled={isDisabledAll && !isReschedule}
                        disabledHours={() =>
                          disabledHours(this.props.activeSpace, from_date)
                        }
                        disabledMinutes={() =>
                          disabledMinutes(from_date, from_time)
                        }
                        showNow={false}
                        className="custom-input"
                        hideDisabledOptions={true}
                        inputReadOnly={true}
                      />
                      {/* 
                        <TimePicker
                        size="large"
                        format="HH:mm"
                        value={from_time}
                        minuteStep={5}
                        onSelect={this.handleChange("from_time")}
                        onChange={this.handleChange("from_time")}
                        disabled={isDisabledAll && !isReschedule}
                        disabledHours={() =>
                          disabledHours(this.props.activeSpace, from_date)
                        }
                        // disabledMinutes={() => disabledMinutes(from_date)}
                        showNow={false}
                        className="custom-input"
                        hideDisabledOptions={true}
                        inputReadOnly={true}
                      />
                        
                        */}
                    </Space>
                  )}

                  <Row className="error mt5">{error?.from_date_time}</Row>
                </Col>
              </Row>

              <Row align="middle" className="mt10">
                <Col span={6}>
                  <label className="label">Duration</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18}>
                  {isDisabledAll && !isReschedule ? (
                    <span>{duration}</span>
                  ) : (
                    <>
                      <Space direction="horizontal" size={12}>
                        <Input
                          className="custom-input"
                          placeholder="In hours"
                          value={duration}
                          size="large"
                          type="number"
                          min={1}
                          step={0.5}
                          style={{ width: 150 }}
                          onChange={this.handleChange("duration")}
                          disabled={isDisabledAll && !isReschedule}
                        />{" "}
                        hr
                      </Space>
                      <Row className="error mt5">{error?.duration}</Row>
                    </>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Row align="middle" className="mt10">
              <Col span={6}>
                <label className="label">Booking Date</label>
                <sup style={{ color: "red" }}>*</sup>
              </Col>

              <Col span={18}>
                {isDisabledAll ? (
                  <span>
                    {moment(from_to_date?.[0]).format("DD-MM-YYYY")}
                    <SwapRightOutlined className="ml10 mr10" />
                    {moment(from_to_date?.[1]).format("DD-MM-YYYY")}
                  </span>
                ) : (
                  <>
                    <Space direction="horizontal" size={12}>
                      <RangePicker
                        size="large"
                        format="DD/MM/YYYY"
                        value={from_to_date}
                        onChange={this.handleChange("from_to_date")}
                        disabled={isDisabledAll}
                        disabledDate={disabledDate({
                          activeDays: activeSpace?.no_of_days,
                        })}
                        className="custom-input"
                      />
                    </Space>

                    <Row className="error mt5">{error?.from_to_date}</Row>
                  </>
                )}
              </Col>
            </Row>
          )}
          {/* Date & time end */}

          {/* Capacity start */}
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Capacity</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col span={18}>
              {isDisabledAll && !isReschedule ? (
                <span>{capacity}</span>
              ) : (
                <>
                  <Input
                    className="custom-input"
                    placeholder="Capacity"
                    size="large"
                    type="number"
                    min={1}
                    step={1}
                    style={{ width: 150 }}
                    value={capacity}
                    onChange={this.handleChange("capacity")}
                    disabled={isDisabledAll && !isReschedule}
                  />
                  <Row className="error mt5">{error?.capacity}</Row>
                </>
              )}
            </Col>
          </Row>
          {/* Capacity end */}

          <div
            className="--avatar mb15 mt15"
            style={{
              width: "100%",
              backgroundColor: "#EEEDFD",
              fontWeight: 500,
            }}
          >
            {isDisabledAll
              ? "Booked resources"
              : "Available resources for booking"}
          </div>

          {/* Available or Booked resources start */}
          {isDisabledAll && !isReschedule ? (
            <>
              <Row align="middle" className="mt10">
                <Col span={6}>
                  <label className="label">Booking Space</label>
                </Col>

                <Col span={18}>{selectedBooking?.resource_id?.name}</Col>
              </Row>

              {selectedBooking?.reschedule_reason && (
                <Row align="middle" className="mt10">
                  <Col span={6}>
                    <label className="label">Remark</label>
                  </Col>

                  <Col span={18}>{selectedBooking?.reschedule_reason}</Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <Row align="middle" className="mt10">
                <Col span={6}>
                  <label className="label">Available Booking Option</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Col span={18}>
                  <Select
                    className="custom-select fw"
                    placeholder="Select your option"
                    value={resource}
                    size="large"
                    allowClear
                    onChange={this.handleChange("resource")}
                    disabled={disabled}
                  >
                    {mAvailableResources?.map(
                      (resource) =>
                        resource &&
                        typeof resource_type === "string" && (
                          <Select.Option
                            key={resource?._id}
                            value={resource?._id}
                          >
                            {resource?.name} - <TeamOutlined />
                            {resource_type.includes("desk")
                              ? `${
                                  resource?.items?.length ?? 0
                                } seat available `
                              : `${resource?.capacity ?? 0} `}
                            - £{resource?.dynamic_price?.net?.toFixed(2)}
                          </Select.Option>
                        )
                    )}
                  </Select>
                  <Row className="error mt5">{error?.resource}</Row>
                </Col>
              </Row>

              {/* Amenities start */}
              {activeAmenities?.length > 0 && (
                <Row align="middle" className="mt10">
                  <Col span={6}>
                    <label className="label">Amenities</label>
                  </Col>

                  <Col span={18}>
                    {activeAmenities?.map((a, i) => (
                      <Tag key={`${a?._id}-${i}`}>{a?.title}</Tag>
                    ))}
                  </Col>
                </Row>
              )}
              {/* Amenities end */}

              {selectedBooking && (
                <Row align="top" className="mt10">
                  <Col span={6}>
                    <label className="label">Remark</label>
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>

                  <Col span={18}>
                    <Input.TextArea
                      className="custom-input"
                      placeholder="Add remark"
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      allowClear
                      value={reschedule_reason}
                      onChange={this.handleChange("reschedule_reason")}
                      disabled={disabled}
                    />

                    <Row className="error mt5">{error?.reschedule_reason}</Row>
                  </Col>
                </Row>
              )}
            </>
          )}
          {/* Available or Booked resources end */}

          {resource_type?.includes?.("desk") &&
            isDeskSwapRequestRaise &&
            bookingStatus !== "new" && (
              <>
                <div
                  className="--avatar mb15 mt15"
                  style={{
                    width: "100%",
                    backgroundColor: "#EEEDFD",
                    fontWeight: 500,
                  }}
                >
                  Desk Swap Request
                </div>

                {/* Customer remark start */}
                {isDeskSwapRequestRaise && (
                  <Row align="top" className="mt10">
                    <Col span={6}>
                      <label className="label">Customer's Remark</label>
                    </Col>

                    <Col span={18}>{customer_remark}</Col>
                  </Row>
                )}
                {/* Customer remark end */}

                {/* Admin remark start */}
                {selectedBooking?.admin_remark && (
                  <Row align="top" className="mt10">
                    <Col span={6}>
                      <label className="label">Admin's Remark</label>
                    </Col>

                    <Col span={18}>{selectedBooking?.admin_remark}</Col>
                  </Row>
                )}
                {/* Admin remark end */}
              </>
            )}

          {/* Desk swap new & old desks start */}
          {selectedBooking?.resource_id?.type?.includes?.("desk") &&
            selectedBooking?.items?.map((item, i) => (
              <Row align="middle" className="mt10" key={i}>
                <Col span={6}>
                  <label className="label">Desk {i + 1}</label>
                </Col>

                <Col span={8}>
                  <Row align={"middle"} justify={"space-between"}>
                    <Col span={8}>
                      <Tag>{item?.seat_no}</Tag>
                    </Col>

                    {selectedBooking?.old_items?.[i]?.seat_no && (
                      <>
                        <SwapRightOutlined />

                        <Col span={8}>
                          <Tag>{selectedBooking?.old_items?.[i]?.seat_no}</Tag>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
          {/* Desk swap new & old desks end */}

          <div
            className="--avatar mb15 mt15"
            style={{
              width: "100%",
              backgroundColor: "#EEEDFD",
              fontWeight: 500,
            }}
          >
            Member details
          </div>

          {/* Billed To start */}
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Billed To</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              {isDisabledAll || isNotNewBooking ? (
                <Tag>{assigned_to || "-"}</Tag>
              ) : (
                <>
                  <Select
                    className="custom-select fw"
                    placeholder="Select billed to member"
                    value={assigned_to}
                    size="large"
                    showSearch
                    allowClear
                    onChange={this.handleChange("assigned_to")}
                    dropdownRender={this._dropdownRender}
                    onDropdownVisibleChange={this.onMemberListVisibleChange}
                    disabled={isDisabledAll}
                  >
                    {activeMemberEmails?.map(
                      (email) =>
                        email && (
                          <Select.Option key={email} value={email}>
                            {email}
                          </Select.Option>
                        )
                    )}
                  </Select>
                  <Row className="error mt5">{error?.assigned_to}</Row>
                </>
              )}
            </Col>
          </Row>
          {/* Billed To end */}

          {/* Member start */}
          <Row align="middle" className="mt10">
            <Col span={6}>
              <label className="label">Member</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={18}>
              {isDisabledAll || isNotNewBooking ? (
                member?.map((email) => <Tag key={email}>{email}</Tag>)
              ) : (
                <>
                  <Select
                    className="custom-select fw"
                    placeholder="Select member"
                    value={member}
                    size="large"
                    allowClear
                    onChange={this.handleChange("member")}
                    mode={("multiple", "tags")}
                    dropdownRender={this._dropdownRender}
                    onDropdownVisibleChange={this.onMemberListVisibleChange}
                    disabled={isDisabledAll}
                  >
                    {members?.map(
                      (email) =>
                        email && (
                          <Select.Option
                            disabled={
                              capacity >= 1 &&
                              member?.length === Number(capacity)
                            }
                            key={email}
                            value={email}
                          >
                            {email}
                          </Select.Option>
                        )
                    )}
                  </Select>
                  <Row className="error mt5">{error?.member}</Row>
                </>
              )}
            </Col>
          </Row>
          {/* Member end */}
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bookingId, fromToDate } = ownProps;
  const selectedBooking = bookingId && state?.booking?.bookingMap?.[bookingId];

  const activeWorkspace = state?.workSpace?.active;

  const boardKey = {
    space_id: activeWorkspace,
  };
  const membersState = state.members;
  const memberMap = membersState?.memberMap;
  const mMemberBoard = membersState?.members?.[JSON.stringify(boardKey)] || {};
  const members = mMemberBoard?.list;

  let activeMemberEmails = [];
  members?.forEach?.((mId) => {
    const mMember = memberMap?.[mId];
    const isThisSpaceActive = mMember?.spaces?.find?.(
      (space) => space?.space === activeWorkspace && space?.active
    );

    if (isThisSpaceActive && mMember?.email) {
      activeMemberEmails.push(mMember?.email);
    }
  });
  activeMemberEmails = activeMemberEmails.filter((email) => email);

  const today = moment();
  let bookingStatus = "";

  if (selectedBooking && !selectedBooking?.active) {
    bookingStatus = "canceled";
  } else if (
    today.isBetween(moment(selectedBooking?.from), moment(selectedBooking?.to))
  ) {
    bookingStatus = "ongoing";
  } else if (moment(selectedBooking?.from).isBefore(today, "time")) {
    bookingStatus = "past";
  }

  return {
    activeWorkspace: activeWorkspace,
    selectedBooking: selectedBooking,
    fromToDate: fromToDate,
    activeSpace: state?.workSpace?.workSpaceMap?.[activeWorkspace],
    booking: state?.booking,
    activeMemberEmails: activeMemberEmails,
    availableResources: state?.booking?.availableResources,
    amenities: state?.amenities?.amenities,
    bookingStatus: bookingStatus,
    loggedInUser: state?.user?.loggedInUser,
    mMemberBoard: mMemberBoard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bookResource: (obj) => dispatch(bookResource(obj)),
    getAllAvailableResources: (obj) => dispatch(getAllAvailableResources(obj)),
    updateBooking: (obj) => dispatch(updateBooking(obj)),
    cancelBooking: (id) => dispatch(cancelBooking(id)),
    addMember: (params) => dispatch(addMember(params)),
    getAllMembers: (params) => dispatch(getAllMembers(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBooking);
